<template>
  <div id="Content">
    <div class="content-inner">
      <breadcrumbs :crumbs="breadcrumbs" />
      <tab-tote
        :tabs="navTabs"
        :value="navTabSelected"
        v-on:click="onClickNavTab"
      />
      <router-view />
    </div>
  </div>
</template>

<script>
import TabTote from "@/components/TabTote.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "brand-dashboard",
  components: {
    Breadcrumbs,
    TabTote
  },
  head() {
    return {
      title: "Brand"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Brand Dashboard", "/brands/my-brand"],
        ["User Permissions", null]
      ],
      navTabs: ["Add User", "Manage Users"],
      subDomain: null
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;
      if (path.endsWith("/add-user")) {
        return 0;
      } else if (path.endsWith("/manage-user")) {
        return 1;
      }
    }
  },
  created() {
    this.$store.commit("setTitle", "User Permissions");
    this.subDomain = this.$route.params.subdomain;
  },
  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;
      if (index === 0 && !path.endsWith("/add-user")) {
        this.$router.push(
          `/brands/user-permissions/${this.subDomain}/add-user`
        );
      } else if (index === 1 && !path.endsWith("/manage-user")) {
        this.$router.push(
          `/brands/user-permissions/${this.subDomain}/manage-user`
        );
      }
    }
  }
};
</script>

<style lang="less" scoped></style>
