<template>
  <div class="user-permissions-form">
    <div class="row">
      <div class="col half">
        <FormulateInput
          type="text"
          label="First Name"
          placeholder="First Name"
          validation="required"
          v-model="formValues.firstName"
          :error="formErrors.firstName"
        />

        <!-- <diyobo-input
          type="text"
          label="First Name"
          placeholder="First Name"
          required
          v-model="formValues.firstName"
          :val="formValues.firstName"
          :error="formErrors.firstName"
        /> -->
      </div>
      <div class="col half">
        <FormulateInput
          type="text"
          label="Last Name"
          placeholder="Last Name"
          validation="required"
          v-model="formValues.lastName"
          :error="formErrors.lastName"
        />
        <!-- <diyobo-input
          type="text"
          label="Last Name"
          placeholder="Last Name"
          required
          v-model="formValues.lastName"
          :val="formValues.lastName"
          :error="formErrors.lastName"
        /> -->
      </div>
      <div class="col half">
        <FormulateInput
          type="text"
          label="Email"
          placeholder="Email"
          validation="required|email"
          v-model="formValues.email"
          :error="formErrors.email"
        />

        <!-- <diyobo-input
          type="text"
          label="Email"
          placeholder="Email"
          required
          v-model="formValues.email"
          :val="formValues.email"
          :error="formErrors.email"
        /> -->
      </div>
      <div class="col half">
        <FormulateInput
          type="tel"
          label="Phone Number"
          placeholder="(204) 000-1111"
          v-model="formValues.phone"
          :error="formErrors.phone"
        />

        <!-- <diyobo-input
          id="phone-input"
          class="profile-input"
          type="tel"
          label="Phone Number"
          placeholder="(204) 000-1111"
          v-model="phoneOutput"
          :autocomplete="'tel'"
          :val="formValues.phone"
          :numsOnly="true"
          :error="formErrors.phone"
        /> -->
      </div>

      <div class="row">
        <h4>Brand Permissions</h4>
        <div class="col full">
          <diyobo-checkbox
            type="check"
            label="All Permissions"
            v-model="formValues.allBrandPermissions"
            :checked="formValues.allBrandPermissions"
          />
        </div>

        <div class="text-divider">
          <hr />
          <p>OR</p>
          <hr />
        </div>

        <div class="row full">
          <div
            v-for="permission in brandTypes"
            class="col half"
            :key="permission.type"
          >
            <diyobo-checkbox
              type="check"
              v-model="formValues.brandPermissions[permission.type]"
              :disabled="formValues.allBrandPermissions"
              :label="permission.name"
              :checked="formValues.brandPermissions[permission.type]"
            />
          </div>
        </div>
        <div class="col">
          <diyobo-button
            small
            class="select-all-permissions"
            type="primary"
            :txt="allBrandsPermissionsSelected ? 'Deselect All' : 'Select All'"
            :disabled="formValues.allBrandPermissions"
            @click="onSelectAllPermissions('brand')"
          />
        </div>
      </div>

      <div class="col half">
        <h4>Events</h4>
        <div class="row">
          <div class="col full">
            <diyobo-checkbox
              type="check"
              label="All Events"
              v-model="formValues.allEvents"
              :checked="formValues.allEvents"
            />
          </div>
          <div class="text-divider">
            <hr />
            <p>OR</p>
            <hr />
          </div>
          <div class="col full">
            <FormulateInput
              type="multiSelectSearch"
              label="Select Specific Event"
              placeholder="Select event(s)"
              :options="eventsForDropdown"
              v-model="formValues.events"
              :disabled="formValues.allEvents"
              use-count-label
            />

            <!-- <multiple-select
              id="age-group"
              label="Select Specific Events"
              placeholder="%n Event(s) Selected"
              :options="eventsForDropdown"
              :readOnly="formValues.allEvents"
              @input="onSelectEvent"
            /> -->
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="row">
      
    </div>
    <div class="row">
    
    </div> -->

    <h4>Event Permissions</h4>
    <div class="row">
      <diyobo-checkbox
        type="check"
        label="All Permissions"
        v-model="formValues.allPermissions"
        :checked="formValues.allPermissions"
      />
    </div>
    <div class="text-divider">
      <hr />
      <p>OR</p>
      <hr />
    </div>
    <div class="row">
      <div v-for="permission in types" class="col half" :key="permission.type">
        <diyobo-checkbox
          type="check"
          v-model="formValues.permissions[permission.type]"
          :disabled="formValues.allPermissions"
          :label="permission.name"
          :checked="formValues.permissions[permission.type]"
        />
      </div>
    </div>
    <div class="row">
      <diyobo-button
        small
        class="select-all-permissions"
        type="primary"
        :txt="allPermissionsSelected ? 'Deselect All' : 'Select All'"
        :disabled="formValues.allPermissions"
        @click="onSelectAllPermissions('event')"
      />
    </div>

    <h4>Email Permissions</h4>
    <div class="row">
      <diyobo-checkbox
        type="check"
        label="All Permissions"
        v-model="formValues.allEmailPermissions"
        :checked="formValues.allEmailPermissions"
      />
    </div>
    <div class="text-divider">
      <hr />
      <p>OR</p>
      <hr />
    </div>
    <div class="row">
      <div v-for="permission in emails" class="col half" :key="permission.type">
        <diyobo-checkbox
          type="check"
          v-model="formValues.emailPermissions[permission.type]"
          :disabled="formValues.allEmailPermissions"
          :label="permission.name"
          :checked="formValues.emailPermissions[permission.type]"
        />
      </div>
    </div>
    <div class="row">
      <diyobo-button
        small
        class="select-all-permissions"
        type="primary"
        :txt="allEmailPermissionsSelected ? 'Deselect All' : 'Select All'"
        :disabled="formValues.allEmailPermissions"
        @click="onSelectAllPermissions('event')"
      />
    </div>

    <div class="row">
      <diyobo-button type="primary" txt="Submit" @click="onSubmit" />
    </div>
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import DiyoboInput from "@/components/DiyoboInput.vue";
import MultipleSelect from "@/components/MultipleSelect.vue";
import { formatPhoneNumber } from "@/helpers/input-formats";
import * as yup from "yup";

const VALIDATION_SCHEMA = yup.object({
  firstName: yup.string().required("First Name is required."),
  lastName: yup.string().required("Last Name is required."),
  email: yup
    .string()
    .email()
    .required("Email is required."),
  phone: yup.string().nullable()
});

export default {
  name: "UserPermissionsForm",
  components: {
    DiyoboButton,
    DiyoboCheckbox,
    DiyoboInput,
    MultipleSelect
  },
  props: {
    events: Array,
    brandTypes: Array,
    types: Array,
    brandUrl: String,
    emails: Array,
  },
  data() {
    return {
      eventsForDropdown: this.events.map(e => ({
        label: e.name,
        value: e._id
      })),
      formValues: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        allEvents: false,
        brandUrl: "",
        events: [],
        allPermissions: false,
        permissions: this.types.reduce((a, b) => {
          a[b.type] = false;
          return a;
        }, {}),
        allBrandPermissions: false,
        brandPermissions: this.brandTypes.reduce((a, b) => {
          a[b.type] = false;
          return a;
        }, {}),
        allEmailPermissions: false,
        emailPermissions: this.emails.reduce((a, b) => {
          a[b.type] = false;
          return a;
        }, {}),
      },
      formErrors: {
        firstName: "",
        lastName: "",
        email: "",
        phone: ""
      }
    };
  },
  computed: {
    allPermissionsSelected() {
      return Object.values(this.formValues.permissions).every(t => t);
    },
    allEmailPermissionsSelected() {
      return Object.values(this.formValues.emailPermissions).every(t => t);
    },
    allBrandsPermissionsSelected() {
      return Object.values(this.formValues.brandPermissions).every(t => t);
    },
    phoneOutput: {
      get() {
        return this.formValues.phone;
      },
      set(value) {
        this.formValues.phone = formatPhoneNumber(value);
      }
    }
  },
  methods: {
    validateAt(field) {
      VALIDATION_SCHEMA.validateAt(field, this.formValues)
        .then(() => {
          this.formErrors[field] = "";
        })
        .catch(err => {
          this.formErrors[field] = err.message;
        });
    },
    // onSelectEvent(value, selected) {
    //   this.$set(
    //     this.eventsForDropdown.find(p => p.value === value),
    //     "selected",
    //     selected
    //   );

    //   if (selected) {
    //     this.formValues.events.push(value);
    //   } else {
    //     this.formValues.events = this.formValues.events.filter(
    //       e => e !== value
    //     );
    //   }
    // },
    onSelectAllPermissions(type) {
      if (type === "event") {
        const selected = !this.allPermissionsSelected;

        Object.keys(this.formValues.permissions).forEach(k => {
          this.formValues.permissions[k] = selected;
        });
      } else if (type === "brand") {
        const selected = !this.allBrandsPermissionsSelected;

        Object.keys(this.formValues.brandPermissions).forEach(k => {
          this.formValues.brandPermissions[k] = selected;
        });
      }
    },
    async onSubmit() {
      try {
        await VALIDATION_SCHEMA.validate(this.formValues, {
          abortEarly: false
        });
      } catch (error) {
        error.inner.forEach(err => {
          this.formErrors[err.path] = err.message;
        });
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth"
        });
        return;
      }

      this.formValues.brandUrl = this.brandUrl;
      this.$loader.start();

      const permissions = {
        ...this.formValues
      };

      this.$axios
        .post("/permissions/user/add", permissions)
        .then(response => {
          const data = response.data;

          this.$emit("submit", data.user);

          this.formValues.firstName = "";
          this.formValues.lastName = "";
          this.formValues.email = "";
          this.formValues.phone = "";
          this.formValues.allEvents = false;
          this.formValues.events = [];
          this.formValues.allPermissions = false;
          this.formValues.permissions = this.types.reduce((a, b) => {
            a[b.type] = false;
            return a;
          }, {});
          this.formValues.allBrandPermissions = false;
          this.formValues.brandPermissions = this.types.reduce((a, b) => {
            a[b.type] = false;
            return a;
          }, {});
          this.formValues.allEmailPermissions = false;
          this.formValues.emailPermissions = this.emails.reduce((a, b) => {
            a[b.type] = false;
            return a;
          }, {});
          // for (const event of this.eventsForDropdown) {
          //   event.selected = false;
          // }
        })
        .catch(error => {
          if (error && error.response) {
            this.$toast(error.response.data, { type: "error" });
          }
        })
        .finally(() => {
          this.$loader.stop();
        });
    }
  }
};
</script>

<style lang="less" scoped>
.user-permissions-form {
  .text-divider {
    position: relative;
    display: flex;
    color: #999999;
    height: 20px;
    width: 100%;
    margin-bottom: 0.5em;

    p {
      position: relative;
      margin: 0 12px;
    }

    hr {
      width: 100%;
      height: 0.1px;
      border: rgba(91, 91, 91, 0.5) solid 0.5px;
      margin: auto;
    }
  }

  button {
    margin: 16px 0 0 auto;

    &.select-all-permissions {
      margin: 16px auto 0 0;
    }
  }
}
</style>
