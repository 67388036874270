<template>
  <div>
    <template v-if="users === null">
      <loader />
    </template>
    <template v-else>
      <h3>Manage Users</h3>
      <div v-if="users.length === 0" class="users">
        You have no user permissions.
      </div>
      <div v-else class="users">
        <user-permissions-tile
          v-for="user in users"
          :value="user"
          :types="types"
          :brandTypes="brandTypes"
          :events="events"
          :emails="emails"
          :key="user.id"
          @delete="onDeleteUser"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Loader from "@/components/Loader.vue";
import UserPermissionsTile from "../../../components/permissions/UserPermissionsTile.vue";

export default {
  name: "manage-user-permissions",
  components: {
    UserPermissionsTile,
    Loader,
    Breadcrumbs
  },
  head() {
    return {
      title: "User Permissions"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["User Permissions", null]
      ],
      users: null,
      events: [],
      types: [],
      brandTypes: [],
      emails: [],
    };
  },

  methods: {
    onDeleteUser(user) {
      const index = this.users.indexOf(user);

      if (index > -1) {
        this.users.splice(index, 1);
      }
    }
  },
  created() {
    let subdomain = this.$route.params.subdomain;
    this.$axios.post("/permissions", { url: subdomain }).then(response => {
      const data = response.data;
      this.users = data.users;
      this.events = data.events;
      this.brandTypes = data.brandTypes;
      this.types = data.types;
      this.invites = data.invites;
      this.emails = data.emails;
    });
  }
};
</script>

<style lang="less" scoped>
</style>
