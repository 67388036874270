<template>
  <div>
    <template v-if="users === null">
      <loader />
    </template>
    <template v-else>
      <h3>Add User</h3>
      <user-permissions-form
        :events="events"
        :brandTypes="brandTypes"
        :types="types"
        :emails="emails"
        :brandUrl="subdomain"
        @submit="onAddUser"
      />
    </template>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";
import UserPermissionsForm from "../../../components/permissions/UserPermissionsForm.vue";

export default {
  name: "add-user-permissions",
  components: {
    UserPermissionsForm,
    Loader
  },
  head() {
    return {
      title: "User Permissions"
    };
  },
  data() {
    return {
      users: null,
      events: [],
      types: [],
      emails: [],
      brandTypes: [],
      subdomain: null
    };
  },
  methods: {
    onAddUser(user) {
      this.users.unshift(user);
      this.$bus.$emit("alert", {
        msg: `Invite sent to ${user.email}!`
      });
    }
  },
  created() {
    let subdomain = this.$route.params.subdomain;
    this.subdomain = subdomain;
    this.$axios.post("/permissions", { url: subdomain }).then(response => {
      const data = response.data;
      this.users = data.users;
      this.events = data.events;
      this.brandTypes = data.brandTypes;
      this.types = data.types;
      this.invites = data.invites;
      this.emails = data.emails;
    });
  }
};
</script>

<style lang="less" scoped>
</style>
