<template>
  <div class="user-permissions-tile">
    <Accordion :title="accordionTitle" v-model="show">
      <div class="event-inner">
        <div class="left">
          <div class="event-details">
            <div class="more-info">
              <div class="event-name">
                {{ value.user.first_name }} {{ value.user.last_name }}
              </div>
              <div class="event-date">
                {{ value.user.email }}
              </div>
              <diyobo-checkbox
                v-if="value.invite_accepted"
                type="check"
                label="Enabled"
                v-model="formValues.enabled"
                :checked="formValues.enabled"
              />
              <div v-else>Invite Pending</div>
              <diyobo-button
                small
                type="cancel"
                txt="Delete"
                @click="onClickDelete(value)"
              />
              <diyobo-button
                small
                type="secondary"
                txt="Resend"
                @click="onClickResend(value)"
              />
            </div>

            <div class="row events-selection">
              <div class="col full">
                <h4>Events</h4>
                <div class="col full">
                  <diyobo-checkbox
                    type="check"
                    label="All Events"
                    v-model="formValues.allEvents"
                    :checked="formValues.allEvents"
                  />
                </div>
                <div class="text-divider">
                  <hr/>
                  <p>OR</p>
                  <hr/>
                </div>
                <div class="col full">
                  <FormulateInput
                    type="multiSelectSearch"
                    label="Selected Events"
                    placeholder="Select event(s)"
                    :options="eventsForDropdown"
                    v-model="formValues.events"
                    :disabled="formValues.allEvents"
                    use-count-label
                  />

                  <!-- <multiple-select
                  id="selected-events"
                  label="Selected Events"
                  :placeholder="eventsDropdownPlaceholder"
                  :options="eventsForDropdown"
                  :readOnly="formValues.allEvents"
                  @input="onSelectEvent"
                /> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end left -->

        <div class="right">
          <div class="row">
            <h4>Brand Permissions</h4>
            <div class="col full">
              <diyobo-checkbox
                type="check"
                label="All Permissions"
                v-model="formValues.allBrandPermissions"
                :checked="formValues.allBrandPermissions"
              />
            </div>

            <div class="text-divider">
              <hr/>
              <p>OR</p>
              <hr/>
            </div>

            <div class="row full">
              <div
                v-for="permission in brandTypes"
                class="col half"
                :key="permission.type"
              >
                <diyobo-checkbox
                  type="check"
                  v-model="formValues.brandPermissions[permission.type]"
                  :disabled="formValues.allBrandPermissions"
                  :label="permission.name"
                  :checked="formValues.brandPermissions[permission.type]"
                />
              </div>
            </div>
            <div class="row">
              <diyobo-button
                small
                class="select-all-permissions"
                type="primary"
                :txt="
                allBrandsPermissionsSelected ? 'Deselect All' : 'Select All'
              "
                :disabled="formValues.allBrandPermissions"
                @click="onSelectAllPermissions('brand')"
              />
            </div>
          </div>
          <h4>Event Permissions</h4>
          <div class="row">
            <diyobo-checkbox
              type="check"
              label="All Permissions"
              v-model="formValues.allPermissions"
              :checked="formValues.allPermissions"
            />
          </div>
          <div class="text-divider">
            <hr/>
            <p>OR</p>
            <hr/>
          </div>
          <div class="row">
            <div
              v-for="permission in types"
              class="col half"
              :key="permission.type"
            >
              <diyobo-checkbox
                type="check"
                v-model="formValues.permissions[permission.type]"
                :disabled="formValues.allPermissions"
                :label="permission.name"
                :checked="formValues.permissions[permission.type]"
              />
            </div>
          </div>
          <h4>Email Permissions</h4>
          <div class="row">
            <diyobo-checkbox
              type="check"
              label="All Permissions"
              v-model="formValues.allEmailPermissions"
              :checked="formValues.allEmailPermissions"
            />
          </div>
          <div class="text-divider">
            <hr/>
            <p>OR</p>
            <hr/>
          </div>
          <div class="row">
            <div
              v-for="permission in emails"
              class="col half"
              :key="permission.type"
            >
              <diyobo-checkbox
                type="check"
                v-model="formValues.emailPermissions[permission.type]"
                :disabled="formValues.allEmailPermissions"
                :label="permission.name"
                :checked="formValues.emailPermissions[permission.type]"
              />
            </div>
          </div>
          <div class="row">
            <diyobo-button
              small
              type="primary"
              :txt="allPermissionsSelected ? 'Deselect All' : 'Select All'"
              :disabled="formValues.allPermissions"
              @click="onSelectAllPermissions('event')"
            />
          </div>
        </div>
      </div>
    </Accordion>
  </div>
</template>

<script>
import DiyoboCheckbox from "@/components/DiyoboCheckbox.vue";
import MultipleSelect from "@/components/MultipleSelect.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import debounce from "debounce";
import ModalContent from "@/helpers/modals";
import Accordion from "@/components/Accordion.vue";

export default {
  name: "UserPermissionsTile",
  components: {
    Accordion,
    DiyoboCheckbox,
    DiyoboButton,
    MultipleSelect
  },
  props: {
    value: Object,
    index: Number,
    types: Array,
    brandTypes: Array,
    events: Array,
    emails: Array,
  },
  data() {
    return {
      formValues: {
        enabled: this.value.enabled,
        allEvents: this.value.all_events,
        events: this.value.events,
        allPermissions: this.value.all_permissions,
        permissions: this.value.permissions,
        allBrandPermissions: this.value.all_brand_permissions || false,
        brandPermissions: this.value.brand_permissions,
        allEmailPermissions: this.value.all_email_permissions || false,
        emailPermissions: this.value.email_permissions,
      },
      show: false
    };
  },
  watch: {
    formValues: {
      handler: debounce(function () {
        this.onSubmit();
      }, 500),
      deep: true
    }
  },
  computed: {
    accordionTitle() {
      if (this.value.user.first_name && this.value.user.last_name) {
        return `${this.value.user.first_name} ${this.value.user.last_name} (${this.value.user.email})`
      } else {
        return this.value.user.email;
      }
    },
    eventsForDropdown() {
      return this.events.map(e => ({
        label: e.name,
        value: e._id,
        selected: this.formValues.events.includes(e._id)
      }));
    },
    eventsDropdownPlaceholder() {
      const events = this.formValues.events.length;
      return `${events} Event(s) Selected`;
    },
    allPermissionsSelected() {
      return Object.values(this.formValues.permissions).every(t => t);
    },
    allBrandsPermissionsSelected() {
      return Object.values(this.formValues.brandPermissions).every(t => t);
    }
  },
  methods: {
    onSelectEvent(value, selected) {
      if (selected) {
        this.formValues.events.push(value);
      } else {
        this.formValues.events = this.formValues.events.filter(
          e => e !== value
        );
      }
    },
    onSelectAllPermissions(type) {
      if (type === "event") {
        const selected = !this.allPermissionsSelected;

        Object.keys(this.formValues.permissions).forEach(k => {
          this.formValues.permissions[k] = selected;
        });
      } else if (type === "brand") {
        const selected = !this.allBrandsPermissionsSelected;

        Object.keys(this.formValues.brandPermissions).forEach(k => {
          this.formValues.brandPermissions[k] = selected;
        });
      }
    },
    onClickDelete(permissions) {
      const content = ModalContent.deleteUserPermissions;
      this.$bus.$emit("alert", {
        msg: content.msg.replace("{email}", permissions.user.email),
        confirm: content.confirm,
        closable: true,
        onConfirm: () => {
          this.$axios
            .post("/permissions/user/delete", {
              permissionsID: permissions.id
            })
            .then(() => {
              this.$emit("delete", permissions);
            });
        }
      });
    },
    onClickResend(permissions) {
      this.$axios.post('/permissions/user/resend-invite', permissions)
        .then(() => {
          this.$toast('Email has been resent.');
        });
    },
    onSubmit() {
      this.$axios.post("/permissions/user/update", {
        permissionsID: this.value.id,
        ...this.formValues
      });
    }
  }
};
</script>

<style lang="less" scoped>
.user-permissions-tile {
  margin: 0.75em auto;

  // border: 1px solid #333b3f;

  .event-inner {
    border-radius: 8px;
    box-shadow: 1px 1px 2px -1px #ffffff36, inset 0px 1px 5px 0px #00000069;
    // background-image: linear-gradient(#1f1f1f, #161616);
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 52px;

    .left {
      // width: calc(100% - 400px);
      height: inherit;
      margin: 20px 0;

      .more-info {
        button {
          background-color: var(--primary-green);
          margin-top: 12px;
        }
      }

      .event-details {
        padding: 0 20px;
        position: relative;
        // width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .event-name {
          color: var(--text);
          font-size: 1.75em;
          font-weight: 700;
          word-break: break-word;
          line-height: 1em;
        }

        .event-date {
          margin: 10px 0;
          font-weight: 600;
          text-transform: uppercase;
          font-size: 1.05em;
          line-height: 1em;
        }

        .stats {
          display: flex;

          .creation-date {
            position: relative;
            margin-right: 24px;
            color: var(--headers);
            font-size: 1.05em;
            font-weight: 600;
            text-transform: uppercase;
            line-height: 1em;
          }
        }

        .row.events-selection {
          flex: none;
          margin: 40px 0 !important;
        }
      }
    }

    // end left
    .right {
      display: flex;
      flex-wrap: wrap;
      width: 380px;
      margin: 0 20px 20px 20px;

      button {
        margin: 16px auto 0 auto;
      }
    }

    // end right
  }

  .text-divider {
    position: relative;
    display: flex;
    color: #999999;
    height: 20px;
    width: 100%;
    margin-bottom: 0.5em;

    p {
      position: relative;
      margin: 0 12px;
    }

    hr {
      width: 100%;
      height: 0.1px;
      border: rgba(91, 91, 91, 0.5) solid 0.5px;
      margin: auto;
    }
  }

  @media screen and (max-width: 780px) {
    .event-inner {
      max-width: min-content;

      .right {
        .tix-sold {
          width: 261px;
        }

        margin-right: 20px;
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: 456px) {
  .automation-tile {
    .event-inner {
      .right {
        .tix-sold {
          width: 100%;
        }
      }

      .event-details {
        .event-name {
          font-size: 1em;
        }

        .event-date {
          font-size: 0.9em;
        }

        .creation-date {
          font-size: 0.7em;
        }
      }
    }
  }
}
</style>
